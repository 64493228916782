<template>
  <v-col class="regads mt-2">
    <v-col class="shades white pa-6">
      <v-row class="px-4">
        <v-col cols="12">
          <h3
            :style="
              'font-weight: 700; color: var(--default-2-color);' +
              ($vuetify.breakpoint.smAndDown ? 'font-size: 14px' : 'font-size: 18px')
            ">
            가맹점에서 제공하는 제품에 대해 소개해주세요.
          </h3>
        </v-col>
        <v-col cols="12" class="mt-4">
          <div class="label mb-2">제품 등록</div>
          <span class="caption warm-grey--text">품목 목록은 광고등록 후에도 수정이 가능합니다.</span>
          <v-list>
            <div class="line2"></div>
            <v-list-item>
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 50%">제품명</v-list-item-content>
              <v-list-item-content class="mx-0 mt-2 px-1 align-end" style="width: 30%">가격</v-list-item-content>
              <v-list-item-action style="width: 20%">제품 등록/삭제</v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(menu, index) in form.menu" :key="index" class="py-2">
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 50%">{{ menu.name }}</v-list-item-content>
              <v-list-item-content class="mx-0 mt-2 px-1 align-end" style="width: 30%">{{
                menu.price
              }}</v-list-item-content>
              <v-list-item-action style="width: 20%">
                <v-btn
                  color="rouge"
                  class="ma-0 ml-4"
                  outlined
                  @click="delMenu(index)"
                  :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'">
                  <v-icon>close</v-icon>
                  <span class="ml-2 hidden-sm-and-down">삭제</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <div class="line2"></div>
            <v-list-item class="pa-2">
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 40%">
                <v-text-field
                  v-model="menu.name"
                  name="menuname"
                  type="text"
                  :rules="[errors.first('menuname') || !errors.has('menuname')]"
                  placeholder="제품명"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-list-item-content>
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 40%">
                <v-text-field
                  v-model="menu.price"
                  name="menuprice"
                  type="text"
                  :rules="[errors.first('menuprice') || !errors.has('menuprice')]"
                  placeholder="가격"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  class="rouge white-two--text ma-0 ml-4"
                  @click="addMenu(menu)"
                  :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'">
                  <v-icon>add</v-icon>
                  <span class="ml-2 hidden-sm-and-down">추가</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: 'Page5',
  props: ['adsData', 'option'],
  components: {
    // bulbIcon: () => import('@/assets/img/regads/light-bulb.svg')
  },
  data: function () {
    return {
      form: {
        menu: this.adsData.menu || [],
      },
      menu: {},
    };
  },
  watch: {},
  methods: {
    addMenu(menu) {
      this.form.menu.push({ name: menu.name, price: menu.price });
      // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
      this.form.step = 5;
      this.$emit('updateEvent', this.form);
    },
    delMenu(index) {
      this.form.menu.splice(index, 1);
      // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
      this.form.step = 5;
      this.$emit('updateEvent', this.form);
    },
  },
  created() {
    // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
    // this.form.step = 5;
    // this.$emit('updateEvent', this.form);
  },
};
</script>

<style scoped>
::v-deep .v-list-item {
  padding: 0;
  font-size: 14px;
}
</style>
